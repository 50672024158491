<template>
  <div class="flex flex-col justify-center items-center">
    <div class="home max-w-5xl">
      

      <div class="bg-white m-3 p-5 rounded flex flex-col justify-center mb-3" >
        <img alt="Vue logo" src="../assets/logo.png">
        <h1 class="text-4xl mt-5">⭐ CLIENTE PRIME ⭐</h1>
        <p class="mt-2">Estamos muito agradecidos por escolher a Santo Sapato! 🤩</p>

        <p class="mt-3">A partir do preenchimento e envio deste formulário, você terá descontos especiais e receberá nossas novidades e ofertas em primeira mão. </p>

        <p class="mt-3">Santo Sapato é satisfação a cada passo! 👞</p>
      </div>

      <div v-if="finish" class="bg-white m-3 p-5 rounded flex flex-col justify-center mb-3" >
        <p class="mt-2 flex items-center justify-center">
          <svg style="color:green;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="mr-3 bi bi-check-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
          </svg>
          Pronto, o formulário foi enviado!
        </p>
      </div>

      <form v-if='!finish'  @submit.prevent="submit">

      <div class="bg-white m-3 p-10 rounded flex flex-col justify-start items-start mb-3" >
        <label for="" class="font-bold">Email*</label>
        <input v-model="email" class="w-full border-b focus:border-gray-400 outline-none rounded h-12 p-3" required type="email" name="" id="">
      </div>

      <div class="bg-white m-3 p-10 rounded flex flex-col justify-start items-start mb-3" >
        <label for="" class="font-bold">Nome Completo*</label>
        <input v-model="name" class="w-full border-b focus:border-gray-400 outline-none border-gray-200 rounded h-12 p-3" required type="text" name="" id="">
      </div>

      <div class="bg-white m-3 p-10 rounded flex flex-col justify-start items-start mb-3" >
        <label for="" class="font-bold">Data de Nascimento*</label>
        <input v-model="birthday" class="w-full  border-b focus:border-gray-400 outline-none rounded h-12 p-3" required type="date" name="" id="">
      </div>

      <div class="bg-white m-3 p-10 rounded flex flex-col justify-start items-start mb-3" >
        <label for="" class="font-bold">Número de Whatsapp com DDD *</label>
        <input v-mask="'(##) #####-####'" v-model="phone" class="w-full  border-b focus:border-gray-400 outline-none rounded h-12 p-3" required type="text" name="" id="">
      </div>

      <div class="bg-white m-3 p-10 rounded flex flex-col justify-start items-start mb-3" >
        <label for="" class="font-bold">Endereço *</label>
        <input v-model="address" class="w-full  border-b focus:border-gray-400 outline-none rounded h-12 p-3" required type="text" name="" id="">
      </div>

      <div class="bg-white m-3 p-10 rounded flex flex-col justify-start items-start mb-3" >
        <label for="" class="font-bold mb-5">Qual tamanho do seu calçado geralmente? *</label>
        <div v-for='(item, i) in 45' :key='i'  class="flex justify-center">
          <input v-if="item>=35 && item<=45" @click="changeSizeshoes(item)" class="h-5 w-5 p-3 mr-2 mb-2" type="checkbox" name="" :id="'n'+item">
          <label v-if="item>=35 && item<=45" :for="'n'+item">{{item}}</label>
        </div>
      </div>

      <div class="bg-white m-3 p-5 rounded flex flex-col justify-start items-start mb-3" >
        <input class="w-full hover:bg-blue-600 bg-blue-500 text-white rounded p-3" type="submit" value="Enviar" name="" id="">
      </div>

      </form>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import {getAll, addOne} from "../firebase"


export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data(){
    return{
      name:null,
      email:null,
      birthday:null,
      phone:null,
      address:null,
      size_shoes:[],

      finish:false
    }
  },
  methods:{
    async submit(){
      let data = {
        name:this.name,
        email:this.email,
        birthday:this.birthday,
        phone:this.phone,
        address:this.address,
        shoes_size: JSON.stringify(this.size_shoes)
      }

      let result = addOne("question", data)

      if(result){
        this.finish=true;
      }else{
        this.finish=false
      }
    },
    changeSizeshoes(data){
      if(!this.size_shoes.filter(item=>item==data).length){
        this.size_shoes.push(data)
      }else{
        this.size_shoes = this.size_shoes.filter(item=>item!=data)
      }
      console.log()
    }
  },
  mounted(){
    document.querySelector("body").style.backgroundColor = "#eee8dd"
  }
}
</script>
