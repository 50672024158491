import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, setDoc, doc, addDoc, deleteDoc } from 'firebase/firestore/lite';
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZLLaT9Erdyp7DKkCzrDknMTu3RJbez34",
  authDomain: "academia-404b5.firebaseapp.com",
  projectId: "academia-404b5",
  storageBucket: "academia-404b5.appspot.com",
  messagingSenderId: "277152364815",
  appId: "1:277152364815:web:5ef128f0875d6a2fd7e67a"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function getAll(table) {
  const dataCol = collection(db, table);
  const dataSnapshot = await getDocs(dataCol);
  const dataList = dataSnapshot.docs.map(doc => {
    return {id:doc.id,...doc.data()}
  });
  return dataList;
}

async function delOne(table, id) {
    let result = await deleteDoc(doc(db, table, id));
    return result
}

async function  addOne(table, data){
    const result = await addDoc(collection(db, table), {
        ...data
        // name:this.name,
        // email:this.email,
        // birthday:this.birthday,
        // phone:this.phone,
        // address:this.address,
        // shoes_size: JSON.stringify(this.size_shoes)
    });

    return result
}

export {getAll, addOne, delOne}